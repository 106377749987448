import React from 'react';
import { View, SafeAreaView, StyleSheet, Image, Text } from 'react-native';
import Player from './Component/Player';
import TopHeader from './Component/TopHeader';
import BottomFooter from './Component/BottomFooter';


export default function App() {

  const styles = StyleSheet.create({
    container: {
      height: "100vh",
      flex: 1,
      backgroundColor: "black"
    },

    top: {
      height: "10vh"
    },

    center: {
      height: "80vh",
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
      flex: 1
    },

    bottom: {
      height: "10vh",
      alignItems: 'center',
      justifyContent: 'center'
    },
    img: {
      width: "95vw",
      height: ""
    },
    text: {
      color: "white"
    },
    imgNext: {
      width: "20vh",
      height: "6vh"
    },
    divNext: {
      margin: "30px"
      
    },
    textBody: {
      color : "white",
      textAlign: "center",
      fontWeight: "bold",
      fontSize: "24px",
      fontStyle: "italic"
    },
    alterText : {
      color: "purple",
    },
    textContainer : {
      marginTop : "30px"
    }
  });

  return (<SafeAreaView style={styles.container}>
    <View style={styles.top}>
      {/* <TopHeader/> */}
    </View>
    <View style={styles.center}>
    <Player />
    <View style = {styles.textContainer}>
    <Text style = {styles.textBody}>{`"POSARE IL `}
    <Text style = {styles.alterText}>{`DISCO`}</Text> {`SUL \n PIATTO DI UN `}
    <Text style = {styles.alterText}>{`GIRADISCHI`}</Text> {`\n E ABBASSARE LA PUNTINA \n È UN GESTO `} 
    <Text style = {styles.alterText}>{`REVERENZIALE`}</Text>{`" \n \n we willrockyou very soon`}</Text>
    </View>
      <View style = {styles.divNext}>
        <Text style={styles.text}>In collaborazione con ..</Text>
        <Image
          source={require('./image/next.jpg')}
          style={styles.imgNext}
        />

      </View>
    </View>
    <View style = {styles.bottom}>
      <BottomFooter/>
    </View>
  </SafeAreaView>
  )
}
import React, { useState } from 'react';
import { Text, View, StyleSheet, Image, TouchableOpacity } from 'react-native';


const styles = StyleSheet.create({

    container: {
        alignItems: 'center',
        justifyContent: 'center'
    },
    textStateRadio: {
        color: "white",
        textAlign: "center"
    }

})

export default function Player() {

    const [state, setState] = useState({
    stateRadio: "Siamo online! Premi il giradischi ed inizia ad ascoltare la nostra musica!",
    booleanStateRadio: true,
    url : 'https://video1.getstreamhosting.com:8658/stream',
    isPlay: false
})
    const audio = new Audio(state.url);

    const changeState = () =>{ 
        setState({
            stateRadio : "Ci dispiace siamo momentaneamente offiline.",
            booleanStateRadio : false,
        })
        
        }

    audio.addEventListener('error',e => failed(e));

    function failed(e) {
        // audio playback failed - show a message saying why
        // to get the source of the audio element use $(this).src
        switch (e.target.error.code) {
            case e.target.error.MEDIA_ERR_ABORTED:
                changeState()
                break;
            case e.target.error.MEDIA_ERR_NETWORK:
                changeState()
                break;
            case e.target.error.MEDIA_ERR_DECODE:
                changeState()
                break;
            case e.target.error.MEDIA_ERR_SRC_NOT_SUPPORTED:
                changeState()
                break;
            default:
                changeState()
                break;
        }
    }

const playPause = () => {

    if(!state.isPlay){
    audio.play();
    setState(prevState => ({
        ...prevState,
        isPlay : true
    }))
}
}

return (<View style={styles.container}>

    <TouchableOpacity onPress={playPause}>
        <Image
            style={{ width: 120, height: 120 }}
            source={require('../image/giradischi.png')}
        />
    </TouchableOpacity>

    <Text style={styles.textStateRadio}>{state.stateRadio}</Text>

</View>
)
}
import React from 'react';
import { Text, StyleSheet, View } from 'react-native';

const styles = StyleSheet.create({

    text: {
        color : "white"
    }

})


export default function BottomFooter () {

    return (<View>
        <Text style = {styles.text}>Copyright© Anthony Sguera</Text>
        </View>)
}